import React from 'react';
// import { Carousel } from 'antd'; 
import { Modal, Button, Space } from 'antd';
import { Link, graphql } from 'gatsby';
import ReactPlayer from 'react-player';
import SiteLayout from '@layouts/site-layout';
import ModalVideo from 'react-modal-video'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const our_partner = {
    dots: false,
    infinite: true,
    autoplay:true,
    arrows:true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3, 
        }
      },
      {
         breakpoint: 640,
         settings: {
           slidesToShow: 2, 
         }
       },
       {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, 
        }
      },
   ]
  }; 

  const our_team = {
    dots: false,
    infinite: true,
    autoplay:true,
    arrows:true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3, 
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
         breakpoint: 600,
         settings: {
           slidesToShow: 2, 
           slidesToScroll: 2,
           initialSlide: 2
         }
       },
       {
        breakpoint: 480,
        settings: {
          slidesToShow: 1, 
          slidesToScroll: 1
        }
      },
   ]
  }; 


export default class InfoPage extends React.Component {
    state = { visible: false ,
        isOpen: false,
        videoId:'',
        playing:false
    };

    openModal = this.openModal.bind(this)
    onPlay = this.onPlay.bind(this)
   

  showModal = () => {
    this.setState({
      visible: true,
    }); 
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
    });
};
onPlay (videoId) {
    this.setState({isOpen: true,
        videoId:videoId
  
    })
  }

 
  openModal (videoId) {
      console.log(videoId);
      
    this.setState({isOpen: true,
        playing:false,
                   videoId:videoId
                })
  }

 

    render() {
        let description =  `STEPapp, created by a team of 400+ IITians and Doctors after extensive research, is a unique
        e-learning software product that provides conceptual content delivery in a gamified format. Our
        team comes with deep experience and expertise gathered over many years of teaching and
        training students from various socio-economic strata of the country, preparing them for various
        reputed national and international competitive exams, and turning them into the best and
        brightest this country has ever seen.`
        let img = `img/stepapp_logo.png`
        return (
            <SiteLayout title="About Us" description={description} image={img}>
                <div className="top_margin_comn">
                    <div className="comn_bkgimg home_slider_sec" 
                    style={{background:"url('../img/abt_banner_slide.jpg') no-repeat center center /cover", color:"#fff"}}
                    > 
                        <div className="container">
                            <h3>About Us</h3>
                            {/* <div className="brdcum">
                            <Link to="/">Index</Link> - About Us
                            </div> */}
                        </div>
                    </div>

                    <div className="bannsec_imgtext">
                        <div className="left_img">
                            <img src="img/about_us.png" alt="about image missing" />
                        </div>
                        <div className="right_text about_text">
                            <h3>Our Story</h3> 
                            <p>STEPapp, created by a team of 400+ IITians and Doctors after extensive research, is a unique
                            e-learning software product that provides conceptual content delivery in a gamified format. Our
                            team comes with deep experience and expertise gathered over many years of teaching and
                            training students from various socio-economic strata of the country, preparing them for various
                            reputed national and international competitive exams, and turning them into the best and
                            brightest this country has ever seen.</p>
                            <p>Naturally, with this much experience in the education industry, our team developed a keen understanding of the gaps in the current education system and how those gaps could be filled with a gamified learning system that acts as an add-on to traditional classroom teaching.</p>
                        </div>
                    </div> 

                    <div className="container">
                        <div className="try_solve">
                            <h3>What We Are Trying To Solve</h3>
                            <p>The idea behind the conception of STEPapp is to primarily provide a level playing field in
                            education. The idea is to bolster four key parameters that make education effective and
                            complete. These key parameters are Accessibility, Quality, Enjoyability, and Affordability
                            (AQEA in short).</p>
                        </div>
                    </div> 

                    <div className="bg_color_gray">
                        <div className="about-banner">
                            <img src="img/abut_side.jpg" alt="about school images" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-6">
                                    <div className="box_grp">
                                        <h3>Accessibility</h3>
                                        <p>Ensuring children can learn anywhere and anytime with an app that can be played offline and is readily available in a device-agnostic form. Also ensuring that learning outcomes are reported to parents, teachers, and policymakers and helping them play a more effective role in children’s education.</p>
                                    </div>
                                </div>
                                <div className="col-6">
                                        <div className="box_grp">
                                            <h3>Quality</h3>
                                            <p>Ensuring standardised quality of learning for all with content created by a team of 400+ IITians and doctors delivered in gamified format. The learning content, developed after extensive research lasting over 5 years, has been field-tested in a variety of schools across the country with sterling feedback.</p>
                                        </div>
                                    </div>
                                <div className="col-6">
                                    <div className="box_grp">
                                        <h3>Enjoyability</h3>
                                        <p>Ensuring learning becomes fun and enjoyable with study content delivered in gamified content that increases retention, replay value, and attention span. The syllabus is broken down into the smallest possible quanta in the form of concepts that a child can play through and learn at their own pace.</p>
                                    </div>
                                </div>
                                
                                    <div className="col-6">
                                        <div className="box_grp">
                                            <h3>Affordability</h3>
                                            <p>Ensuring that the delivery of high-quality learning is made possible for students belonging to all socio-economic backgrounds with the most affordable prices in the market. STEPapp is proudly partnered with state and central government schools across the country to ensure that conceptual is provided to all students. </p>
                                        </div>
                                    </div>
                               </div>
                        </div>
                    </div>

            <div className="bannsec_imgtext our_mission"> 
                <div className="right_text">
                    <h3>Our Mission</h3>
                    <p>Our mission is to create a pool of meritorious children who will go on to become national assets. Our goal is to reward students for talent, sincerity, and hard work and to help create an equal - opportunity education system in the country.</p>
                    <p>With our focus on the AQEA in education, here’s who we think will benefit the most from our vision:</p>
                    <ul className="our_list"> 
                        <li>
                            <span>
                                <img src="img/p1.png" alt="" />
                            </span>
                            <div className="o_text">
                                <h4>Students</h4>
                                <p>With access to fun and enjoyable learning in a gamified format,  followed by mentorship and guidance from our team of IITians and Doctors for meritorious students.</p>  
                            </div>
                        </li>
                        <li>
                            <span><img src="img/p2.png" alt="" /></span>
                        <div className="o_text"> 
                            <h4>Parents</h4>
                            <p>With access to timely reports of children’s learning outcomes from a 360° perspective, by testing the student’s speed, accuracy, and the number of attempts while they play and learn.</p>  
                        </div>
                        </li>
                        <li>
                            <span><img src="img/p3.png" alt="" /></span>
                            <div className="o_text">
                            <h4>Teachers</h4>
                            <p>With access to a helping hand in the form of a homework and revision app, that supports and augments classroom teaching with a self-paced, gamified learning programme.</p> </div>
                        </li>
                        <li>
                            <span><img src="img/p4.png" alt="" /></span>
                            <div className="o_text">
                            <h4>Policymakers</h4>
                            <p> With access to granular, real-time status of learning outcomes across the area of jurisdiction that will help create effective education policies and implementation strategies.</p>  
                        </div>
                        </li> 
                    </ul>
                </div>
                <div className="left_img">
                    <img src="img/abt-right.png" alt="about image missing" />
                </div>
            </div> 


            <div className="">
                <div className="container">
                    <h4 className="commn_heading">The Future of Education is Smart</h4>  
                    <div>
                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.videoId} onClose={() => this.setState({isOpen: false})} />
                    {/* <button onClick={this.openModal}>Open</button> */}
                    </div> 
                    <Slider {...our_partner} className="row patnar-slide our_team_sec">
                        <div>
                            <div className="col-md-12">
                                <div className="blog_text_area"> 
                                {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='y-AxlATegLY' onClose={() => this.setState({isOpen: false})} /> */}
                                {/* <button onClick={()=>this.openModal('y-AxlATegLY')}>  */}
                                <ReactPlayer className="react_player" 
                                 url={this.state.isOpen === false ? 'https://youtu.be/y-AxlATegLY': ''}  
                                  onPlay={() => this.onPlay('y-AxlATegLY')} 
                                  width='100%' height='100%' controls='false'
                                //  light={true}
                                //   playIcon={<button onClick={()=>this.openModal('y-AxlATegLY')}>Open</button> }
                                  />
                                  {/* <img src="img/p3.png" alt="" /> */}
                                <h3>Reactions of students of school run by Chairman ICSE Board after playing STEPapp</h3>
                                {/* </button> */}
                                </div>
                            </div>
                        </div>  
                        <div>
                            <div className="col-md-12">
                                    <div className="blog_text_area">
                                    {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='oiO41WdfOw8' onClose={() => this.setState({isOpen: false})} /> */}
                                   {/* <button onClick={()=>this.openModal('oiO41WdfOw8')}>Open</button> */}
                                    <ReactPlayer className="react_player" 
                                     url={this.state.isOpen === false ? 'https://www.youtube.com/watch?v=7NT-at15bAU': ''}  
                                      width='100%' height='100%' controls='true'  
                                      onPlay={() => this.onPlay('7NT-at15bAU')}
                                      />
                                        <h3>New age solution of learning for the nation Stepapp</h3> 
                                    </div>
                                </div>
                            </div>

                            <div>
                            <div className="col-md-12">
                                    <div className="blog_text_area">
                                    {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='oiO41WdfOw8' onClose={() => this.setState({isOpen: false})} /> */}
                                   {/* <button onClick={()=>this.openModal('oiO41WdfOw8')}>Open</button> */}
                                    <ReactPlayer className="react_player" 
                                     url={this.state.isOpen === false ? 'https://youtu.be/oiO41WdfOw8': ''}  
                                      width='100%' height='100%' controls='true'  
                                      onPlay={() => this.onPlay('oiO41WdfOw8')}
                                      />
                                        <h3>Reactions of students of Sulochanadevi Singhania School, Mumbai after playing STEPapp</h3> 
                                    </div>
                                </div>
                            </div>

                            <div>
                                    <div className="col-md-12">
                                    <div className="blog_text_area">
                                    <ReactPlayer className="react_player"  
                                     url={this.state.isOpen === false ? 'https://youtu.be/2hx_WOmf-u8': ''} 
                                     onPlay={() => this.onPlay('2hx_WOmf-u8')}
                                    width='100%' height='100%' controls='true' />
                                      {/* <img src="img/p3.png" alt="" /> */}
                                        <h3>Reactions of students of GD Somani School, Cuff Parade, Mumbai, after playing STEPapp</h3>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="col-md-12">
                                <div className="blog_text_area">
                                <ReactPlayer className="react_player" 
                                url={this.state.isOpen === false ? 'https://youtu.be/9JQVgo9beW8': ''} 
                                onPlay={() => this.onPlay('9JQVgo9beW8')}
                                width='100%' height='100%' controls='true' />
                                    <h3>Feedback of Teacher of EMRS Khairi Parsoda on implementation of STEPapp</h3>
                                </div>
                            </div>
                        </div>

                            <div>
                                 <div className="col-md-12">
                                    <div className="blog_text_area">
                                    <ReactPlayer className="react_player"
                                     url={this.state.isOpen === false ? 'https://youtu.be/xwj9LI3USQg': ''} 
                                     onPlay={() => this.onPlay('xwj9LI3USQg')}
                                     width='100%' height='100%' controls='true' />
                                        <h3>Feedback of Principal of JNV Jalgaon on implementation of STEPapp</h3>
                                    </div>
                                </div>
                            </div>

                         <div>
                            <div className="col-md-12">
                                <div className="blog_text_area">
                                <ReactPlayer className="react_player"
                                  url={this.state.isOpen === false ? 'https://youtu.be/AlZlUA5EsdE': ''} 
                                  onPlay={() => this.onPlay('AlZlUA5EsdE')}
                                 width='100%' height='100%' controls='true' />
                                    <h3>Mr Deepak S Parekh HDFC Bank Chairman</h3>
                                 
                                </div>
                            </div>
                        </div>                                
                    </Slider> 
                </div>
            </div>

            <div className="bkg_blue">  
                <div className="container">  
                  <h4 className="commn_heading">Our Team</h4>   
                     <Slider {...our_team} className="row patnar-slide our_team_sec">
                        <div>
                            <div className="col-md-12">
                               <div className="single-patnar text-center mt-20">
                                <img src="img/t1.jpg" alt="Logo" />
                                </div> 
                                <div className="team_text">
                                    <span>Mr. Praveen Tyagi </span>
                                    <h4>Founder & CEO </h4>
                                    {/* <p> B.Tech IIT Delhi</p>  */}
                                </div>
                            </div> 
                        </div>  

                        <div>
                            <div className="col-md-12">
                               <div className="single-patnar text-center mt-20">
                                <img src="img/rajeev-sir.jpg" alt="Logo" />
                                </div> 
                                <div className="team_text">
                                    <span>Mr. Rajeev Belani </span>
                                    <h4>Chief Technical Officer</h4> 
                                </div>
                            </div> 
                        </div>  

                        <div>
                            <div className="col-md-12">
                               <div className="single-patnar text-center mt-20">
                                    <img src="img/Nikhila.jpg" alt="Logo" />
                                </div>
                                <div className="team_text">
                                    <span>Ms. Nikhila Varun </span>
                                    <p>Creative Director</p>
                                </div>
                            </div>
                        </div>

                        {/* <div>
                            <div className="col-md-12">
                               <div className="single-patnar text-center mt-20">
                                    <img src="img/t3.jpg" alt="Logo" />
                                </div>
                                <div className="team_text">
                                    <span> Gaurav Goyal</span>
                                    <h4>Chief Marketing Officer </h4> 
                                </div>
                            </div>
                        </div> */}


                        <div>
                            <div className="col-md-12">
                               <div className="single-patnar text-center mt-20">
                                    <img src="img/t2.jpg" alt="Logo" />
                                </div>
                                <div className="team_text">
                                    <span>Mr. Sandeep Singhal</span>
                                    <h4>Chief Business Officer <br/> Physics Mentor </h4>
                                    {/* <p>B.Tech IIT Roorkee</p> */}
                                </div>
                            </div>
                        </div> 

                        {/* <div>
                            <div className="col-md-12">
                               <div className="single-patnar text-center mt-20">
                                    <img src="img/t4.jpg" alt="Logo" />
                                </div>
                                <div className="team_text">
                                    <span>Mr. Anshul Singhal  </span>
                                    <p> Chief Content Officer & Mathematics Mentor </p>
                                </div>
                            </div>
                        </div>  */}

                        <div>
                            <div className="col-md-12">
                               <div className="single-patnar text-center mt-20">
                                    <img src="img/vishaljain.jpg" alt="Logo" />
                                </div>
                                <div className="team_text">
                                    <span> Mr. Vishal Jain </span>
                                    <p> Chemistry Mentor  </p>
                                </div>
                            </div>
                        </div>  
                        

                        <div>
                            <div className="col-md-12">
                               <div className="single-patnar text-center mt-20">
                                    <img src="img/jtygo1.jpg" alt="Logo" />
                                </div>
                                <div className="team_text">
                                    <span>Ms. Jyotsna Tyagi </span>
                                    <p> Chemistry Mentor </p>
                                </div>
                            </div>
                        </div> 

                        <div>
                            <div className="col-md-12">
                               <div className="single-patnar text-center mt-20">
                                    <img src="img/sakshi.jpg" alt="Logo" />
                                </div>
                                <div className="team_text">
                                    <span>Ms. Sakshi Chinchanikar </span>
                                    <p> Biology Mentor</p>
                                </div>
                            </div>
                        </div>

                        {/* <div>
                            <div className="col-md-12">
                               <div className="single-patnar text-center mt-20">
                                    <img src="img/shailesh.jpg" alt="Logo" />
                                </div>
                                <div className="team_text">
                                    <span>Mr. Shailesh Kumar </span>
                                    <p>Chief Financial Officer</p>
                                </div>
                            </div>
                        </div> */}
                     </Slider>  
               </div>
               </div>  
            </div>

                {/* <div> 
                    <Modal
                    title=" "
                    destroyOnClose={true}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    >
                    <p>
                        <ReactPlayer url='https://www.youtube.com/embed/oiO41WdfOw8' playing  width='100%' height='350px' />
                    </p> 
                    </Modal> 
                </div>    */}
            </SiteLayout>

             
            
        )
    }
}
